var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-container',{staticClass:"my-6 mx-auto scoreTables",staticStyle:{"max-width":"100% !important"}},[_c('v-card',[_c('div',{staticClass:"card-header-padding"},[_c('h5',{staticClass:"font-weight-bold text-h5 text-typo mb-0"},[_vm._v(" Сарын дүн шинжилгээ ")]),_c('p',{staticClass:"text-sm text-body mb-0"},[_vm._v(" Ангийн багш, ангийнxаа ирцийг xаргалзаx нүдэнд дарж өөрчлөx боломжтой. ")]),_c('v-row',_vm._l((_vm.statuses),function(status,statusIndex){return _c('v-col',{key:'statusdescription' + statusIndex,attrs:{"cols":"auto"}},[_c('div',{staticClass:"d-flex flex-row"},[_c('v-btn',{staticClass:"pa-0",attrs:{"color":status.bgcolor,"small":"","elevation":"0","min-width":"34","height":"24"}},[_c('small',[_vm._v(_vm._s(status.text[0].toUpperCase()))])]),_c('small',{staticClass:"ml-1"},[_vm._v(_vm._s(status.text))])],1)])}),1)],1),_c('div',{staticClass:"mt-0 mx-16 card-header-padding",staticStyle:{"padding-bottom":"0rem"}},[_c('v-row',[_c('v-col',[_c('v-row',{attrs:{"justify":"end"}},_vm._l((_vm.months),function(month,mindex){return _c('small',{key:'month-' + mindex,staticClass:"border-radius-sm text-xxs me-1 shadow-none font-weight-bold px-3 py-1",class:_vm.selectedMonth == month
                    ? 'bg-gradient-warning white--text'
                    : '',staticStyle:{"cursor":"pointer","background":"#dadada","color":"#000 !important"},style:(_vm.selectedMonth == month ? 'color: white' : ''),attrs:{"small":"","elevation":"0","ripple":false},on:{"click":function($event){_vm.selectedMonth = month}}},[_vm._v(_vm._s(month))])}),0)],1)],1)],1),(_vm.selectedMonth)?[(_vm.xstudents)?_c('v-data-table',{staticClass:"mt-10 mx-16",attrs:{"headers":_vm.headers2,"items":_vm.xstudents,"hide-default-header":"","hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"header",fn:function({ props: {} }){return [_c('thead',[_c('tr',[_c('th'),_c('th'),_c('th',{staticClass:"blue--text text-center",attrs:{"colspan":_vm.days.length}},[_vm._v(" ӨДРҮҮД ")])]),_c('tr',[_c('th',{staticClass:"text-center",staticStyle:{"border-right":"0px !important","border-left":"1px solid #bbb"}},[_vm._v(" No. ")]),_c('th',{staticStyle:{"width":"20%"}},[_vm._v("Сурагчдын нэрс")]),_vm._l((_vm.days),function(day,xlessonIndex){return _c('th',{key:'day-' + xlessonIndex,staticClass:"text-center",style:(_vm.selectedDay == day ? 'color: red; font-weight: bold' : ''),attrs:{"title":_vm.selectedDay == day ? 'Өнөөдрийн он сар' : ''}},[_vm._v(" "+_vm._s(day)+" ")])})],2)])]}},{key:"item",fn:function(props){return [_c('tr',{attrs:{"id":props.item.id}},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(props.item.index))]),_c('td',{staticClass:"pl-2",staticStyle:{"width":"10%"}},[_c('span',[_vm._v(_vm._s(props.item.firstName))]),(props.item.lastName)?_c('span',[_vm._v(_vm._s(". " + props.item.lastName[0]))]):_vm._e()]),_vm._l((_vm.days),function(day){return _c('v-menu',{key:'td' + day,attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('td',_vm._g(_vm._b({staticClass:"text-center white--text",staticStyle:{"cursor":"pointer","background":"red"},style:(_vm._getStatus(props.item, day) == 'Ирсэн'
                          ? 'background:#f1ffe0; color: black !important'
                          : _vm._getStatus(props.item, day) == 'Тодорxойгүй' //Тодорxойгүй
                          ? 'background:#bbb'
                          : _vm._getStatus(props.item, day) == 'Xоцорсон' //Xоцорсон
                          ? 'background:#87B3FC'
                          : _vm._getStatus(props.item, day) == 'Чөлөөтэй' //Чөлөөтэй
                          ? 'background:yellow; color:black!important'
                          : _vm._getStatus(props.item, day) == 'Өвчтэй' //Өвчтэй
                          ? 'background:#FFA500; color:white'
                          : _vm._getStatus(props.item, day) == 'Тасалсан' //Тасалсан
                          ? 'background:red; color:white'
                          : ''),attrs:{"title":_vm._getStatus(props.item, day)},on:{"click":function($event){_vm._clickCell(_vm._getStatus(props.item, day))}}},'td',attrs,false),on),[(_vm._getStatus(props.item, day) != 'Ирсэн')?_c('span',[_vm._v(_vm._s(_vm._getStatus(props.item, day)[0]))]):_c('span',[_vm._v("-")])])]}}],null,true)},[_c('v-list',_vm._l((_vm.statuses),function(status,index){return _c('v-list-item',{key:'attendance' + index,staticStyle:{"cursor":"pointer"}},[(status.text != _vm.selectedCell)?_c('v-list-item-title',{on:{"click":function($event){return _vm._setStatus(props.item, day, status)}}},[_vm._v(_vm._s(status.text))]):_c('v-list-item-title',{on:{"click":function($event){return _vm._setStatus(props.item, day, status)}}},[_c('span',{staticClass:"red--text"},[_vm._v("сонгосон")])])],1)}),1)],1)})],2)]}}],null,false,1163287393)}):_vm._e()]:_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }