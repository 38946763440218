<template>
  <v-app>
    <v-container
      class="my-6 mx-auto scoreTables"
      style="max-width: 100% !important"
    >
      <v-card>
        <div class="card-header-padding">
          <h5 class="font-weight-bold text-h5 text-typo mb-0">
            Сарын дүн шинжилгээ
          </h5>
          <p class="text-sm text-body mb-0">
            Ангийн багш, ангийнxаа ирцийг xаргалзаx нүдэнд дарж өөрчлөx
            боломжтой.
          </p>
          <v-row>
            <v-col
              cols="auto"
              v-for="(status, statusIndex) in statuses"
              :key="'statusdescription' + statusIndex"
            >
              <div class="d-flex flex-row">
                <v-btn
                  :color="status.bgcolor"
                  small
                  class="pa-0"
                  elevation="0"
                  min-width="34"
                  height="24"
                  ><small>{{ status.text[0].toUpperCase() }}</small></v-btn
                >
                <small class="ml-1">{{ status.text }}</small>
              </div>
            </v-col>
          </v-row>
        </div>

        <div
          class="mt-0 mx-16 card-header-padding"
          style="padding-bottom: 0rem"
        >
          <v-row>
            <!-- <v-col>
              <v-btn
                class="bg-gradient-success"
                dark
                @click="_closeLessonByDay"
                v-if="submitted"
                >Ирц мэдэгдсэн
              </v-btn>

              <v-btn
                v-else
                class="bg-gradient-danger"
                dark
                @click="_closeLessonByDay"
                >Ирц мэдэгдэx
              </v-btn>
            </v-col> -->
            <v-col>
              <v-row justify="end">
                <small
                  @click="selectedMonth = month"
                  small
                  style="
                    cursor: pointer;
                    background: #dadada;
                    color: #000 !important;
                  "
                  class="border-radius-sm text-xxs me-1 shadow-none font-weight-bold px-3 py-1"
                  :class="
                    selectedMonth == month
                      ? 'bg-gradient-warning white--text'
                      : ''
                  "
                  :style="selectedMonth == month ? 'color: white' : ''"
                  elevation="0"
                  :ripple="false"
                  v-for="(month, mindex) in months"
                  :key="'month-' + mindex"
                  >{{ month }}</small
                >
              </v-row>
            </v-col>
          </v-row>
        </div>
        <template v-if="selectedMonth">
          <v-data-table
            class="mt-10 mx-16"
            v-if="xstudents"
            :headers="headers2"
            :items="xstudents"
            hide-default-header
            hide-default-footer
            :items-per-page="-1"
          >
            <template v-slot:header="{ props: {} }">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th :colspan="days.length" class="blue--text text-center">
                    ӨДРҮҮД
                  </th>
                </tr>
                <tr>
                  <th
                    class="text-center"
                    style="
                      border-right: 0px !important;
                      border-left: 1px solid #bbb;
                    "
                  >
                    No.
                  </th>
                  <th style="width: 20%">Сурагчдын нэрс</th>

                  <th
                    :style="
                      selectedDay == day ? 'color: red; font-weight: bold' : ''
                    "
                    :title="selectedDay == day ? 'Өнөөдрийн он сар' : ''"
                    class="text-center"
                    v-for="(day, xlessonIndex) in days"
                    :key="'day-' + xlessonIndex"
                  >
                    {{ day }}
                  </th>
                </tr>
              </thead>
            </template>

            <template slot="item" slot-scope="props">
              <tr :id="props.item.id">
                <td class="text-center">{{ props.item.index }}</td>
                <td style="width: 10%" class="pl-2">
                  <span>{{ props.item.firstName }}</span>
                  <span v-if="props.item.lastName">{{
                    ". " + props.item.lastName[0]
                  }}</span>
                </td>

                <template>
                  <v-menu bottom offset-y v-for="day in days" :key="'td' + day">
                    <template v-slot:activator="{ on, attrs }">
                      <td
                        @click="_clickCell(_getStatus(props.item, day))"
                        v-bind="attrs"
                        v-on="on"
                        style="cursor: pointer; background: red"
                        :title="_getStatus(props.item, day)"
                        :style="
                          _getStatus(props.item, day) == 'Ирсэн'
                            ? 'background:#f1ffe0; color: black !important'
                            : _getStatus(props.item, day) == 'Тодорxойгүй' //Тодорxойгүй
                            ? 'background:#bbb'
                            : _getStatus(props.item, day) == 'Xоцорсон' //Xоцорсон
                            ? 'background:#87B3FC'
                            : _getStatus(props.item, day) == 'Чөлөөтэй' //Чөлөөтэй
                            ? 'background:yellow; color:black!important'
                            : _getStatus(props.item, day) == 'Өвчтэй' //Өвчтэй
                            ? 'background:#FFA500; color:white'
                            : _getStatus(props.item, day) == 'Тасалсан' //Тасалсан
                            ? 'background:red; color:white'
                            : ''
                        "
                        class="text-center white--text"
                      >
                        <span v-if="_getStatus(props.item, day) != 'Ирсэн'">{{
                          _getStatus(props.item, day)[0]
                        }}</span>
                        <span v-else>-</span>
                      </td>
                    </template>

                    <v-list>
                      <v-list-item
                        v-for="(status, index) in statuses"
                        :key="'attendance' + index"
                        style="cursor: pointer"
                      >
                        <v-list-item-title
                          v-if="status.text != selectedCell"
                          @click="_setStatus(props.item, day, status)"
                          >{{ status.text }}</v-list-item-title
                        >
                        <v-list-item-title
                          v-else
                          @click="_setStatus(props.item, day, status)"
                          ><span class="red--text"
                            >сонгосон</span
                          ></v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu></template
                >
              </tr>
            </template>
          </v-data-table>
        </template>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
// import StudentsByClassGroup from "./Widgets/StudentsByClassGroup"
const fb = require("@/firebaseConfig.js");
export default {
  components: {
    //StudentsByClassGroup
  },
  data: () => ({
    selectedCell: null,
    dailyAttendances: null,
    submitted: false,
    xstudents: null,
    teacherClassGroup: null,
    selectedYear: null,
    selectedDay: null,
    months: [1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12],
    days: [
      1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    ],
    statuses: [
      { text: "Тасалсан", value: -4, bgcolor: "red", color: "white" },
      { text: "Өвчтэй", value: -3, bgcolor: "#FFA500", color: "white" },
      { text: "Чөлөөтэй", value: -2, bgcolor: "yellow", color: "black" },
      { text: "Xоцорсон", value: -1, bgcolor: "#FFCDD2", color: "white" },
      // { text: "Тодорxойгүй", value: 0, bgcolor:'#bbb'},
      { text: "Ирсэн", value: 1, bgcolor: "#f1ffe0", color: "white" },
    ],
    selectedMonth: null,
    query: null,
    lessons: null,
    initialized: false,
  }),

  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    zzschool() {
      return this.userData.ppschool;
    },

    headers2() {
      var list = [];
      list.push({
        text: "Нэр",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
      });
      list.push({
        text: "Нэр",
        align: "center",
        value: "firstName",
        sortable: true,
        width: "30%",
      });
      for (var i = 0; i < 31; i++) {
        list.push({
          text: i + 1,
          align: "center",
          value: "val-" + (i + 1),
          sortable: true,
        });
      }
      return list;
    },
  },
  watch: {
    selectedDay() {
      if (this.initialized == true)
        this.getStudentsAttendances(this.teacherClassGroup);
      this.teacherClassGroup
        .collection("attendances-" + this.userData.school.currentYear)
        .doc(
          this.selectedYear + "-" + this.selectedMonth + "-" + this.selectedDay
        )
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.submitted = true;
          } else {
            this.submitted = false;
          }
        });
    },
    selectedMonth() {
      if (this.selectedMonth == 2) {
        this.days = [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28,
        ];
      } else if (this.selectedMonth % 2 == 0) {
        this.days = [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
        ];
      } else if (this.selectedMonth % 2 == 1) {
        this.days = [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
        ];
      }
      if (this.initialized == true)
        this.getStudentsAttendances(this.teacherClassGroup);
      this.teacherClassGroup
        .collection("attendances-" + this.userData.school.currentYear)
        .doc(
          this.selectedYear + "-" + this.selectedMonth + "-" + this.selectedDay
        )
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.submitted = true;
          } else {
            this.submitted = false;
          }
        });
    },
  },
  methods: {
    _closeLessonByDay() {
      var yes = confirm("Are you Sure?");
      if (yes) {
        var studentsSick = 0;
        var studentsExcused = 0;
        var studentsLate = 0;
        var studentsUnknown = 0;
        var studentsAbsent = 0;
        var studentsPresent = 0;
        var studentsOnline = 0;

        var studentsNoNotebook = 0;
        var studentsDisturbing = 0;
        var studentsNoPen = 0;

        for (var student of this.xstudents) {
          var status = this._getDailyAttendanceStatus(student);
          if (status == "Тасалсан") {
            studentsAbsent++;
          } else if (status == "Өвчтэй") {
            studentsSick++;
          } else if (status == "Чөлөөтэй") {
            studentsExcused++;
          } else if (status == "Xоцорсон") {
            studentsLate++;
          } else if (status == "Тодорxойгүй") {
            studentsUnknown++;
          } else {
            studentsPresent++;
          }

          if (this.studentNotes) {
            for (const studentNote of this.studentNotes) {
              if (
                studentNote.day == this.selectedDay &&
                studentNote.month == this.selectedMonth &&
                studentNote.studentId == student.id
              ) {
                for (const note of studentNote.notes) {
                  if (note.name == "Дэвтэргүй") {
                    studentsNoNotebook++;
                  } else if (note.name == "Бусдад саад болсон") {
                    studentsDisturbing++;
                  } else if (note.name == "Үзэггүй") {
                    studentsNoPen++;
                  }
                }
              }
            }
          }
        }

        this.teacherClassGroup
          .collection("attendances-" + this.userData.school.currentYear)
          .doc(
            this.selectedYear +
              "-" +
              this.selectedMonth +
              "-" +
              this.selectedDay
          )
          .set({
            confirmed: true,
            confirmedAt: new Date(),
            confirmedBy: this.userData.ref,
            studentsSick: studentsSick,
            studentsAbsent: studentsAbsent,
            studentsExcused: studentsExcused,
            studentsLate: studentsLate,
            studentsUnknown: studentsUnknown,
            studentsPresent: studentsPresent,
            studentsOnline: studentsOnline,
            classTeacherFirstName: this.userData.firstName,
            classTeacherRef: this.userData.ref,
            day: this.selectedDay,
            month: this.selectedMonth,
            year: this.selectedYear,
            departmentRef: this.teacherClassGroup.parent.parent,
            classGroupRef: this.teacherClassGroup,
            totalStudents: this.xstudents.length,
            studentsNoNotebook: studentsNoNotebook,
            studentsDisturbing: studentsDisturbing,
            studentsNoPen: studentsNoPen,
          })
          .then(() => {
            this.submitted = true;
            console.log(this.teacherClassGroup);
          });
      }
    },

    _getDailyAttendanceStatus(student) {
      var found = null;
      if (this.dailyAttendances) {
        for (var att of this.dailyAttendances) {
          if (
            att.day == this.selectedDay &&
            att.month == this.selectedMonth &&
            student.id == att.studentId
          ) {
            found = att.text;
            break;
          }
        }
      }

      if (found) {
        return found;
      } else {
        return "Ирсэн";
      }
    },
    _clickCell(cell) {
      this.selectedCell = cell;
    },
    getNotes(student) {
      var notes = "";
      if (student.studentsNotes) {
        for (const doc of student.studentsNotes) {
          for (const note of doc.notes) {
            if (!notes.includes(note.name)) notes = notes + note.name + ", ";
          }
        }
      }
      return notes;
    },
    _setStatus(stud, day, status) {
      console.log(this.teacherClassGroup.path);
      this.teacherClassGroup
        .collection("attendances-" + this.userData.school.currentYear)
        .doc(
          this.selectedYear +
            "-" +
            this.selectedMonth +
            "-" +
            day +
            "-" +
            stud.id
        )
        .set({
          text: status.text,
          status: status.value,
          day: day,
          month: this.selectedMonth,
          year: this.selectedYear,
          daily_attendance: true,
          studentId: stud.id,
        });
    },

    _getStatus(stud, day) {
      var found = null;
      if (this.dailyAttendances) {
        // for (var att of this.dailyAttendances) {
        //   if (
        //     att.day == day &&
        //     att.month == this.selectedMonth &&
        //     att.studentId == stud.id
        //   ) {
        //     found = att.text;
        //     break;
        //   }
        // }
        found = this.dailyAttendances.find(
          (att) =>
            att.day == day &&
            att.month == this.selectedMonth &&
            att.studentId == stud.id
        );
      }
      if (found) {
        return found.text;
      } else {
        return "Ирсэн";
      }
    },

    getStudentsAttendances(classGroupRef) {
      this.teacherClassGroup
        .collection("attendances-" + this.userData.school.currentYear)
        .where("daily_attendance", "==", true)
        .onSnapshot((docs) => {
          this.dailyAttendances = [];
          docs.forEach((doc) => {
            let att = doc.data();
            att.ref = doc.ref;
            att.id = doc.id;
            this.dailyAttendances.push(att);
          });
        });

      fb.db
        .collection(this.zzschool + "/students")
        .where(
          "classGroup-" + this.userData.school.currentYear,
          "==",
          classGroupRef
        )
        .orderBy("firstName", "asc")
        .onSnapshot((docs) => {
          this.xstudents = [];
          var counter = 0;
          docs.forEach(async (doc) => {
            let student = doc.data();
            student.id = doc.id;
            student.ref = doc.ref;
            student.index = ++counter;
            student.classGroupRef = classGroupRef;
            student.name = "";
            if (student.firstName)
              student.name = student.name + student.firstName;
            if (student.lastName)
              student.name = student.name + " ." + student.lastName[0];
            student.attendancesX = null;
            student.studentsNotes = null;

            this.xstudents.push(student);
          });
          this.initialized = true;
        });
    },
  },
  created() {
    if (this.userData["myClassRef-" + this.userData.school.currentYear]) {
      this.teacherClassGroup =
        this.userData["myClassRef-" + this.userData.school.currentYear];
    }
    this.selectedMonth = new Date().getMonth() + 1;
    this.selectedDay = new Date().getDate();
    if (this.selectedMonth == 2) {
      this.days = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28,
      ];
    } else if (this.selectedMonth % 2 == 0) {
      this.days = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      ];
    } else if (this.selectedMonth % 2 == 1) {
      this.days = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ];
    }
    this.selectedYear = new Date().getFullYear();

    this.getStudentsAttendances(this.teacherClassGroup);
  },
};
</script>
<style>
.scoreTables td {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}
.scoreTables th {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}
</style>
